import { mapState } from 'vuex'
import IconCross from '~/assets/icons/CrossLarge.svg'

export default {
  components: { IconCross },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: null
    },
  },
  computed: {
    ...mapState({
      countryInformation: state => state.dagmar.countryInformation
    }),
    topbarEnabled () {
      return (this.countryInformation?.content?.topbarEnabled && this.countryInformation?.content?.topbarTexts?.length > 0) || false
    },
  },
  watch: {
    show () {
      if (this.show === false) {
        window.removeEventListener('keyup', this.onEscapeKeyUp)
      } else {
        window.addEventListener('keyup', this.onEscapeKeyUp)
      }
    }
  },
  methods: {
    onEscapeKeyUp (event) {
      if (event.which === 27) {
        this.$emit('panelClosed')
      }
    },
    beforeEnter () {
      this.$store.dispatch('header/setPanelIsOpen', true)
      this.$emit('panelBeforeEnter')
      document.body.classList.add('overflow-y-hidden')
    },
    afterLeave () {
      this.$store.dispatch('header/setPanelIsOpen', false)
      this.$emit('panelAfterLeave')
      document.body.classList.remove('overflow-y-hidden')
    }
  }
}
